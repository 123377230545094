import {NavLink, NavLinks} from "../components/headers/light";
import React from "react";

export const navLinks = [
    <NavLinks key={1}>

       <NavLink href="/">
            Home
        </NavLink>
        <NavLink href="/tool">
            Supported Model
        </NavLink>
        <NavLink href="/files">
            Download Software
        </NavLink>
        <NavLink href="/contact-us">
            Contact Us
        </NavLink>
        <NavLink  target="_blank" href="https://reseller.ikogsmtool.com/">
            Reseller Panel
        </NavLink>
        <NavLink  target="_blank" href="https://user.ikogsmtool.com/">
            User Panel
        </NavLink>
        <NavLink href="/about-us">
            About Us
        </NavLink>
    </NavLinks>,
];