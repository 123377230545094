import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "SubContainer/HomePage.js";
import Features from "components/features/ThreeColSimple.js";
import Footer from "components/footers/MiniCenteredFooter";
import Pricing from "../components/pricing/ThreePlans";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Features />
    <Pricing/>
    <Footer />
  </AnimationRevealPage>
);
