import axios from 'axios'

const API = axios.create({baseURL:'https://api.emailjs.com/api/v1.0/email/send'})

export const sentMail = async (data) => {
    return await API.post('',data, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}