import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import Homepage from './MainContainers/EventLandingPage'
import ContactUs from './MainContainers/ContactUs'
import AboutUs from './MainContainers/AboutUs'
import BrandList from './MainContainers/Brands'
import ModelList from './MainContainers/Models'

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route path="/contact-us">
          <ContactUs/>
        </Route>
        <Route path="/about-us">
          <AboutUs/>
        </Route>
        <Route path="/files/:id">
          <ModelList/>
        </Route>
        <Route path="/tool/:id">
          <ModelList/>
        </Route>
        <Route path="/tool">
          <BrandList/>
        </Route>
        <Route path="/files">
          <BrandList/>
        </Route>
        <Route path="/">
          <Homepage/>
        </Route>
      </Switch>
    </Router>
  );
}