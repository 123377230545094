import React,{ useEffect, useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {useLocation,useHistory} from 'react-router-dom'
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import * as API from '../API'
import defaultCardImage from "../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../images/svg-decorator-blob-3.svg";

import CustomizeIconImage from "../images/customize-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12 w-full` }
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {

  const location = useLocation()
  const path = location.pathname.split("/")[1]
   const [brands, setBrands] = useState([]);

  useEffect(() => {
    getBrand()
    return(()=>{
      setBrands([]);
    })
  }, []);
  const getBrand = async () => {
    if(path === "tool"){
      const {data} = await API.getToolBrands()
      setBrands(data)
    }else if(path === "files"){
      const {data} = await API.getFileBrands()
      setBrands(data)
    }
  }
  const history = useHistory()
  const manageRouting = (id) => {
    if(path === "tool"){
      history.push(`/tool/${id}`)
    }else if (path === "files"){
      history.push(`/files/${id}`)
    }
  }


  return (
    <Container>
      <ThreeColumnContainer>
        {
          path === "tool" ?
              <Heading>Supported  Brands</Heading>
              :
              <Heading>Available Brand <span tw="text-primary-500">Files</span> For Download</Heading>
        }
        {brands.map((brand, i) => (
          <Column key={i}>
            <Card onClick={()=>manageRouting(brand.brand_id)}>
              <span className="imageContainer">
                <img src={CustomizeIconImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{brand.brand_name}</span>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
